/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef, useState } from 'react';
import Editor from '@monaco-editor/react';

import SplitPane from 'react-split-pane';

// import { v4 } from 'uuid';
import { get, put, setToken } from './libs/client';

const DEBOUNCE_WAIT = 400;

const USER_TOKENS = [
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c3IiOiI2MzhiN2Y5Y2ZkYjMxYzExZmYyYTcwMGEiLCJyb2wiOiJ1c2VyIiwiaXNzIjoiaGliaWxldC1hcGktbG9jYWwiLCJpYXQiOjE2NzAzNTg2MDY1OTgsImV4cCI6MTcwMTg5NDYwNjU5OH0.yrCVZNU-iHSJeD47Tg2FJzb82XpEWAYSYdbzilkNiYc',
];

function AppBar({ onLoadSession, onChangeUserType }) {
  const [sessionId, setSessionId] = useState('638fa3f7cc26c28637744ab6');

  return (
    <nav className="navbar navbar-light bg-light">
      <img
        src="hibilet-organizer.svg"
        height="36px"
        className="mr-2"
        alt="logo"
      />
      <div className="d-flex flex-grow-1 align-items-center">
        <h4 className="m-0">Oturma Planı Motoru</h4>
      </div>
      <div className="d-flex justify-content-center">
        <select
          className="form-select mr-2"
          style={{ minWidth: '10rem' }}
          onChange={(e) => onChangeUserType(e.target.value)}
        >
          <option value="user">Kullanıcı</option>
          <option value="organizer">Organizatör</option>
        </select>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Seans ID'si"
            value={sessionId}
            onChange={(e) => setSessionId(e.target.value)}
          />
          <div className="input-group-append">
            <button className="btn btn-outline-secondary" type="button" onClick={() => onLoadSession(sessionId)}>Seans Yükle</button>
          </div>
        </div>
      </div>
    </nav>
  );
}

function OrganizerControls({
  categories, onCategoryChange, onModeChange,
}) {
  return (
    <main className="controls">
      <div>
        <label className="form-label mr-2">Kategori</label>
        <select
          disabled={categories.length < 1}
          className="form-select mr-2"
          onChange={(e) => {
            onCategoryChange(categories.findIndex(({ _id }) => _id === e.target.value));
          }}
        >
          {categories.map(({ name, _id }) => <option key={_id} value={_id}>{name}</option>)}
        </select>
      </div>
      <div>
        <label className="form-label mr-2">Hareket Modu</label>
        <select
          className="form-select mr-2"
          onChange={(e) => onModeChange(e.target.value)}
        >
          <option value="POST/ Roam">Roam</option>
          <option value="POST/ MultiSelectAdd">Multi Add</option>
          <option value="POST/ MultiSelectRemove">Multi Remove</option>
        </select>
      </div>
    </main>
  );
}

function UserControls({
  categories, onCategoryChange, onUserChange,
}) {
  return (
    <main className="controls">
      <div>
        <label className="form-label mr-2">Kategori</label>
        <select
          disabled={categories.length < 1}
          className="form-select mr-2"
          onChange={(e) => {
            onCategoryChange(categories.findIndex(({ _id }) => _id === e.target.value));
          }}
        >
          {categories.map(({ name, _id }) => <option key={_id} value={_id}>{name}</option>)}
        </select>
      </div>
      <div>
        <label className="form-label mr-2">Kullanıcı</label>
        <select
          className="form-select mr-2"
          onChange={(e) => onUserChange(e.target.value)}
        >
          {USER_TOKENS.map((u, i) => (<option key={u} value={u}>{`Kullanıcı ${i + 1}`}</option>))}
        </select>
      </div>
    </main>
  );
}

function App() {
  const [rawLayout, setRawLayout] = useState();

  const [dragging, setDragging] = useState(false);
  const [log, setLog] = useState([]);

  const [userType, setUserType] = useState('user');
  const [category, setCategory] = useState(0);

  const [session, setSession] = useState(null);
  const _session = useRef(session);

  const [engineState, setEngineState] = useState();
  const _engineState = useRef(engineState);

  const [selections, setSelections] = useState();

  const engine = useRef();

  const Log = (msg) => {
    setLog([...log, `${new Date().toISOString()} > ${(typeof (msg) === 'object' ? JSON.stringify(msg) : msg)}`]);
  };

  const Message = (msg) => {
    if (msg.method) {
      Log(`${msg.method}: ${JSON.stringify(msg.body)}`);
      engine?.current?.contentWindow?.postMessage(msg, '*');
    } else { Log('No method given.'); }
  };

  const LoadSession = (data) => {
    Log(`Loaded session ${data._id}`);

    _session.current = data;
    setSession(data);

    setRawLayout(JSON.stringify(data.seating_plan, null, 2));

    Message({ method: 'POST/ Layout', data: data.seating_plan }, '*');

    Message({ method: 'GET/ EngineState' }, '*');

    Message({ method: 'POST/ LoadDistribution', data: data.seating_distribution }, '*');
    Message({ method: 'POST/ Category', data: 0 }, '*');

    Message({ method: 'POST/ PaintSeats' }, '*');
  };

  const LoadOccupation = (data) => {
    const occupation = Array.from(Array(_engineState?.current?.stores?.Layout?.SeatCount)).map((_, i) => {
      if (data[i] !== null) { return (data[i] || 0); }
      if (session?.seating_distribution[i]) {
        if (session?.seating_distribution[i].includes(category)) {
          return 0;
        }
      }
      return -1;
    });
    Message({ method: 'POST/ LoadOccupation', data: occupation }, '*');
    Message({ method: 'POST/ PaintSeats' }, '*');
  };

  const GetSession = (id) => {
    get(`/events/sales/sessions/${id}`)
      .then(({ data }) => LoadSession(data))
      .catch(() => Log(`Can't load session ${id}`));
  };

  const GetOccupation = () => {
    get(`/events/${session?.sale?.event}/sales/${session?.sale?._id}/sessions/${session?._id}/occupations`)
      .then(({ data }) => { LoadOccupation(data); })
      .catch((e) => Log(e.message));
  };

  const SelectSeats = (seats) => {
    seats.map(({ id: seat }) => put(`/events/${_session.current.sale.event}/sales/${_session.current.sale._id}/sessions/${_session.current._id}/occupations`, { seat })
      .then(({ data }) => {
        Log(`Selected seat ${seat} with reference ${data._id}`);
        setSelections([...(selections || []), data]);
      })
      .catch((err) => Log(err)));
  };

  const LoadCategory = (data) => {
    setCategory(data);
    Message({ method: 'POST/ Category', data: 0 }, '*');
    Message({ method: 'POST/ PaintSeats' }, '*');
    if (engineState) { GetOccupation(); }
  };

  const EngineState = (data) => {
    _engineState.current = data;
    setEngineState(data);
  };

  const Controllers = {
    'RES/ SelectedSeats': SelectSeats,
    'RES/ EngineState': EngineState,
  };

  useEffect(() => {
    Message({ method: 'POST/ UserType', data: userType });
    Message({ method: 'POST/ PaintSeats' }, '*');
  }, [userType]);

  // Generic, mobile-too implementations.

  const MessageBroker = ({ origin, data }) => {
    if (origin !== window.origin) {
      try {
        Controllers[data.method](data.data);
      } catch (e) {
        Log(e.message);
      }
    }
  };

  useEffect(() => { if (engineState) { GetOccupation(); } }, [engineState]);
  useEffect(() => { if (engineState && selections && session) { GetOccupation(); } }, [selections, session, engineState]);
  useEffect(() => { window.addEventListener('message', MessageBroker); }, []);

  return (
    <main>
      <AppBar
        onLoadSession={GetSession}
        onChangeUserType={setUserType}
      />
      <section>
        <SplitPane
          split="vertical"
          minSize={200}
          defaultSize={200}
          onDragStarted={() => setDragging(true)}
          onDragFinished={() => setDragging(false)}
        >
          <Editor
            language="json"
            value={rawLayout}
            onChange={(e) => { setRawLayout(e); }}
          />
          <SplitPane
            split="horizontal"
            minSize={400}
            defaultSize="95%"
            onDragStarted={() => setDragging(true)}
            onDragFinished={() => setDragging(false)}
          >
            <section>
              {session && (
                (userType === 'user' ? (
                  <UserControls
                    categories={session?.tickets}
                    onCategoryChange={LoadCategory}
                    onModeChange={(method) => Message({ method }, '*')}
                    onUserChange={(user) => setToken(user)}
                  />
                ) : (
                  <OrganizerControls
                    categories={session?.tickets}
                    onCategoryChange={(data) => Message({ method: 'POST/ Category', data }, '*')}
                    onModeChange={(method) => Message({ method }, '*')}
                    onUserChange={(user) => setToken(user)}
                  />
                ))
              )}
              {dragging && (<div className="overlay" />)}
              <iframe
                ref={engine}
                title="Engine"
                // src="https://seating.hibilet.com"
                src="http://10.241.158.51:1234"
                // src="http://localhost:1234"
              />
            </section>
            <div className="p-2" style={{ overflowY: 'scroll' }}>
              <code style={{ whiteSpace: 'pre-line' }}>{log.join('\n')}</code>
            </div>
          </SplitPane>
        </SplitPane>
      </section>
    </main>
  );
}

export default App;
